import React from 'react';
import { getPlayerStateFromAction, getProgress, getCurrentTime } from 'Helpers';
import { PLAYER_STATUSES, PLAYER_EVENTS } from 'Constants/audio';

const DEFAULT_STATE = {
  current: 0,
  progress: 0,
  duration: 0,
  playStatus: PLAYER_STATUSES.PAUSE,
  muteStatus: PLAYER_STATUSES.UNMUTE
}

export function useAudio({ initialState = DEFAULT_STATE, player, item }) {
  const [state, changeState] = React.useState(initialState);

  function setup() {
    player.current.muted = initialState.muteStatus === PLAYER_STATUSES.MUTE;
    if (initialState.playStatus === PLAYER_STATUSES.PLAY) {
      player.current.play()
    }
    changeState({
      ...initialState,
      duration: player.current.duration
    });
  }

  function resetPlayer() {
    changeState((prevState) => ({
      ...prevState,
      current: 0,
      progress: 0,
      playStatus: PLAYER_STATUSES.PAUSE,
    }));
  }


  function triggerAction(action) {
    const newState = getPlayerStateFromAction(player.current, action);

    changeState({ ...state, ...newState });
  };

  function onProgressChange(progress) {
    if (player && player.current) {
      const currentTime = getCurrentTime(progress, player.current.duration);

      if (!isNaN(currentTime)) {
        player.current.currentTime = currentTime;
      }

      changeState({
        ...state,
        progress,
        current: currentTime
      });
    }
  };

  function handleTimeUpdate() {
    changeState((prevState) => ({
      ...prevState,
      current: player.current.currentTime,
      progress: getProgress(player.current.currentTime, player.current.duration)
    }));
  }

  React.useEffect(() => {
    player.current.addEventListener(PLAYER_EVENTS.DURATION_CHANGE, setup, false);
    player.current.addEventListener(PLAYER_EVENTS.TIME_UPDATE, handleTimeUpdate, false);
    player.current.addEventListener(PLAYER_EVENTS.ENDED, resetPlayer, false);

    return () => {
      player.current.removeEventListener(PLAYER_EVENTS.DURATION_CHANGE, setup, false);
      player.current.removeEventListener(PLAYER_EVENTS.TIME_UPDATE, handleTimeUpdate, false);
      player.current.removeEventListener(PLAYER_EVENTS.ENDED, resetPlayer, false);
    }
  }, [item]);


  return { audioState: state, onProgressChange, triggerAction, };
}
