import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DefaultCheckbox from '@material-ui/core/Checkbox';

export const Checkbox = ({ label, ...props }) => (
  <FormControlLabel
    control={
      <DefaultCheckbox {...props} />
    }
    label={label}
  />
);

Checkbox.defaultProps = {
  color: 'primary'
};
