export function validate(item, validators) {
  if (!item || !validators) {
    return {};
  }

  return Object.keys(validators).reduce(function (res, key) {
    const msg = validators[key](item);
    if (!!msg) {
      res = { ...res, [key]: msg };
    } else {
      delete res[key];
    }
    return res;
  }, {});
}

export function checkRequired(name) {
  return function (item) {
    if ([undefined, null, ''].indexOf(item[name]) > -1) {
      return 'can\'t be blank';
    }
    return null;
  }
}

