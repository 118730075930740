import {
  Button, Dialog, DialogContent, DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  iframe: {
    display: 'block',
    width: '100%',
    height: '520px'
  }
}));

export default React.forwardRef((_, ref) => {
  const classes = useStyles();

  const [state, changeState] = React.useState({
    isOpen: false,
    videoSrc: ''
  });

  function open(videoSrc) {
    changeState({
      isOpen: true,
      videoSrc
    })
  }

  function close() {
    changeState({
      isOpen: false,
      videoSrc: ''
    })
  }

  React.useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <Dialog open={state.isOpen} fullWidth maxWidth="md">
      <DialogContent>
        <iframe className={classes.iframe} src={state.videoSrc} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Close</Button>
      </DialogActions>
    </Dialog>
  );
});
