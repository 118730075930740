import {
  Select, Chip, MenuItem, InputLabel, FormControl, FormHelperText, makeStyles, Checkbox,
  ListItemText
} from '@material-ui/core';
import { v1 as uuidv1 } from 'uuid';

const useStyles = makeStyles(() => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  select: {
    height: 'auto!important',
  }
}));


export const FormMultipleSelect = ({ options, value, label, validationMessages, defaultOptionMessage, onChange, ...props }) => {
  const labelId = uuidv1();
  const { chips, chip, select } = useStyles();

  const optionsToDisplay = options.map(({ id, name }) => (
    <MenuItem key={id} value={id}>
      <Checkbox color="primary" checked={value.indexOf(id) > -1} />
      <ListItemText primary={name} />
    </MenuItem>
  ));

  const defaultOption = React.useMemo(() => {
    return (
      <MenuItem disabled>
        <ListItemText primary={defaultOptionMessage} />
      </MenuItem>
    )
  }, [options.length]);
  const selectedOptions = value.map(id => options.filter(item => item.id === id)[0]);

  return (
    <FormControl variant="outlined" fullWidth label={label} error={!!validationMessages}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select variant="outlined" fullWidth multiple value={value} labelId={labelId}
        label={label} classes={{
          root: select
        }}
        renderValue={(value) => (
          <div className={chips}>
            {selectedOptions.map(({ id, name }) => (
              <Chip key={id} label={name} className={chip} />
            ))}
          </div>
        )}
        {...!!options.length && { onChange }}
        {...props}>
        {optionsToDisplay}
        {!options.length && (defaultOption)}
      </Select>
      <FormHelperText>{validationMessages}</FormHelperText>
    </FormControl>
  )
}
