import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { WhiteTheme } from 'Constants/theme';
import { Notifications } from 'Components/Notifications';
import { CommonDialogs } from 'Components/CommonDialogs';
/**
 * Function render component in container element with props in container 'data' attribute
 * @param {*} componentFn Root component of page
 * @param {*} container Dom element with component props in 'data' attribute
 */
export function renderComponent(componentFn, container = document.getElementById('react-root')) {
  if (!container) {
    console.warn('There is no container for react component');
  }
  const props = JSON.parse(container.getAttribute('data'));
  const content = React.createElement(componentFn, props);
  ReactDOM.render((
    <MuiThemeProvider theme={WhiteTheme}>
      <CssBaseline />
      {content}
    </MuiThemeProvider>
  ), container);
}

export function renderComponentWithNotifications(componentFn, notifications, container = document.getElementById('react-root')) {
  if (!container) {
    console.warn('There is no container for react component');
  }

  const props = JSON.parse(container.getAttribute('data'));
  const content = React.createElement(componentFn, props);
  const dialog = document.getElementById('dialog-root')
  const dialogData = dialog ? JSON.parse(dialog.getAttribute('data')) : {}

  ReactDOM.render((
    <MuiThemeProvider theme={WhiteTheme}>
      <CssBaseline />
      <Notifications notifications={notifications}>
        {content}
      </Notifications>
      <CommonDialogs data={dialogData} />
    </MuiThemeProvider>
  ), container);
}
