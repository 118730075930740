import Qs from 'qs';

/**
 * Helper returns value of query param
 * @param {string} queryParamName query param name
 * @param {string} url url address
 * @returns {string} with value
 */
export function getParamFromQuryString(name, url = window.location.href) {
  const pluralizedName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${pluralizedName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) {
    return null;
  }

  return decodeURIComponent(results[2].replace(/\+/g, ''));
}

/**
 * Helper returns query params string
 * @param {object} queryObj object of query params
 */
export function toQueryString(queryObj) {
  return Qs.stringify(queryObj);
}
