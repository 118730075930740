import { IconButton, Icon, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import store from 'Helpers/notifications';

const useStyles = makeStyles(() => ({
  icon: {
    color: 'white'
  },
}));

export const Notifications = ({ children, notifications }) => {
  const provider = React.useRef(null);
  const classes = useStyles();

  function hideNotification(key) {
    return function () {
      provider.current.closeSnackbar(key);
    }
  }

  function addNotification({ type, text }) {
    provider.current.enqueueSnackbar(text, {
      variant: type,
      action: key => (
        <IconButton onClick={hideNotification(key)}>
          <Icon className={classes.icon}>highlight_off</Icon>
        </IconButton>
      )
    });
  }

  store.register('add', addNotification);
  React.useEffect(function () {
    notifications.forEach(addNotification);
  }, []);

  return (
    <SnackbarProvider ref={provider} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }} disableWindowBlurListener>
      {children}
    </SnackbarProvider>
  )
}
