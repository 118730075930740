import Highcharts from 'highcharts';
import { Preloader } from 'Components';

const DEFAULT_CHART_HEIGTH = 400;
const VERTICAL_CHART_MAX_ITEM_COUNT = 15;

export const Chart = React.memo(
  ({ pending, label, categories, data, type, yText, tooltipHeaderText, columnReverse, heightResizeble }) => {
    const container = React.useRef(null);

    function drawChart() {
      Highcharts.chart(container.current, {
        chart: { type },
        title: { text: label },
        xAxis: {
          categories,
        },
        yAxis: {
          title: { text: yText },
        },
        tooltip: {
          headerFormat: tooltipHeaderText ? `${tooltipHeaderText}: <b>{point.key}</b><br/>` : '',

          pointFormat: `${yText}: <b>{point.y}</b>`,
        },
        legend: { enabled: false },
        exploring: { enabled: false },
        series: [{ data, ...(columnReverse && { type: 'bar' }) }],
      });
    }

    React.useEffect(function () {
      (function (Highcharts) {
        Highcharts.wrap(Highcharts.Legend.prototype, 'renderItem', function (
          proceed,
          item
        ) {
          proceed.call(this, item);

          var isPoint = !!item.series,
            collection = isPoint ? item.series.points : this.chart.series,
            element = item.legendGroup.element;

          element.onmouseover = function () {
            if (isPoint) {
              Highcharts.each(collection, function (seriesItem) {
                if (seriesItem === item) {
                  Highcharts.each(item.series.data, function (data) {
                    if (data.drilldown == item.drilldown) {
                      item.series.chart.tooltip.refresh(data);
                    }
                  });
                }
              });
            }
          };
          element.onmouseout = function () {
            if (isPoint) {
              item.series.chart.tooltip.hide();
            }
          };
        });
      })(Highcharts);
    }, []);

    const containerStyles = React.useMemo(() => {
      /* that all elements are visible in the column (400 - default height of chart) */
      if (!heightResizeble || pending || data.length <= VERTICAL_CHART_MAX_ITEM_COUNT) {
        return {}
      }

      return {
        height: (data.length / VERTICAL_CHART_MAX_ITEM_COUNT) * DEFAULT_CHART_HEIGTH
      }
    }, [data])

    React.useEffect(
      function () {
        if (!pending) {
          drawChart();
        }
      },
      [data, pending]
    );

    if (!!pending) {
      return <Preloader />;
    }
    return <div ref={container} style={containerStyles}></div>;
  }
);
