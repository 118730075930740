export function setColorStyle(color = '#000000') {
  if (typeof color === 'string') {
    return color;
  }
  return `rgba(${color.r},${color.g},${color.b},${color.a})`;
}

export function getColorFromString(colorStr) {
  if (colorStr.indexOf('rgb') > -1) {
    const [r, g, b, a] = colorStr.match(/rgba*\(([^)]*)\)/)[1].split(',');
    return { r, g, b, a, };
  }
  return colorStr;
}
