import { Menu, } from '@material-ui/core';

const initialState = {
  mouseX: null,
  mouseY: null,
};

export const ContextMenu = React.forwardRef(({ children = [] }, ref) => {
  const [state, setState] = React.useState(initialState);

  function handleOpen(e) {
    e.preventDefault();
    setState({
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4,
    });
  }

  function handleClose() {
    setState(initialState);
  }

  function bindItemClick(item) {
    return function (e) {
      handleClose();
      item.props.onClick(e);
    }
  }

  React.useImperativeHandle(ref, () => {
    return {
      open: handleOpen,
    }
  });

  return (
    <Menu
      keepMounted
      open={state.mouseY !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        state.mouseY !== null && state.mouseX !== null
          ? { top: state.mouseY, left: state.mouseX }
          : undefined
      }
    >
      {children.map((child, index) => React.cloneElement(child, { key: child.key || index, onClick: bindItemClick(child) }))}
    </Menu>
  );
});
