import { AJAX } from 'Helpers';
import { DEFAULT_PAGINATION_PARAMS } from 'Constants/pagination';

/**
 * paginationFactory returns pagination params and change value params function 
 * @param {object} pagination object of default pagination params.
 * it must have "page" and "size_per_page" keys
 */
export function paginationFactory(pagination, sorting) {

  if (!pagination && !sorting) {
    return {}
  }

  const [paginationParams, changePaginationParams] = React.useState({
    ...pagination,
    ...sorting
  });

  function handleChangePagination(pagination) {
    changePaginationParams({ ...paginationParams, ...pagination });
  }

  return { paginationParams, handleChangePagination }
}

export function listFetchFactory({ fetchUrl, fetchMethod, paginationParams, onSuccessFetch }) {
  const [state, changeState] = React.useState({
    pending: false,
    data: [],
    records_filtered: 0,
    records_total: 0,
  });

  React.useEffect(function () {

    fetchData({ body: { ...paginationParams || {} } });
  }, Object.values(paginationParams || {}));

  function defaultOnSuccessPage(newData) {
    changeState({ ...state, ...newData, pending: false, });
  }

  function fetchData(params) {
    changeState({ ...state, pending: true });
    const request = AJAX[fetchMethod](fetchUrl, params);
    if (onSuccessFetch === undefined) {
      request.then(function (res) {
        defaultOnSuccessPage(res);
      });
    } else if (!!onSuccessFetch) {
      request.then(onSuccessFetch);
    }
    return request;
  }

  return { ...state, fetchData }
}



export function listFactory({ fetchUrl, fetchMethod = 'get', onSuccessFetch,
  pagination = DEFAULT_PAGINATION_PARAMS, sorting = {} }) {
  const paginationData = paginationFactory(pagination, sorting);
  const { paginationParams } = paginationData;
  const listData = listFetchFactory({ fetchUrl, fetchMethod, paginationParams, onSuccessFetch })

  return { ...listData, ...paginationData };
}
