import { FormControl, InputLabel, Select as DefaultSelect, makeStyles, } from '@material-ui/core';
import { v1 as uuidv1 } from 'uuid';

const useStyles = makeStyles(() => ({
  formControl: {
    marginBottom: '10px'
  },
}));

export const Select = ({ label, validationMessages, ...props }) => {
  const labelId = `label-${uuidv1()}`;
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl} fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <DefaultSelect
        labelId={labelId}
        label={label}
        {...props}
        error={!!validationMessages}
      >
      </DefaultSelect>
    </FormControl>
  );
}
