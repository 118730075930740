import { Popper, Grow, Paper, ClickAwayListener, makeStyles } from '@material-ui/core';
import { SketchPicker, } from 'react-color';
import { getColorFromString, setColorStyle, } from 'Helpers';

const useStyles = makeStyles(() => ({
  popup: {
    zIndex: 1
  },
}));

export const ColorPicker = React.forwardRef(({ anchor, value, onChange, ...colorPickerProps }, ref) => {
  const classes = useStyles();
  const [isOpen, setOpen] = React.useState(false);
  const open = () => {
    setOpen(!isOpen);
  }

  const close = () => {
    if (anchor.current && anchor.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleChange({ rgb, source, }) {
    onChange(setColorStyle(rgb));
    if (!source) {
      picker.current.close();
    }

  }

  React.useImperativeHandle(ref, function () {
    return { isOpen, open, close, };
  });

  const color = React.useMemo(function () {
    return getColorFromString(value);
  }, [value]);

  return (
    <Popper open={isOpen} anchorEl={anchor.current} role={undefined}
      placement="bottom-end" transition className={classes.popup}>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={close}>
              <SketchPicker {...colorPickerProps} color={color} onChange={handleChange} />
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
})
