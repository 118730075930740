import { Preloader } from 'Components';
import { Table as MaterialTable, TableContainer, TableBody, TableCell, TableHead, TableRow, TablePagination, makeStyles, TableSortLabel } from '@material-ui/core';

const useStylesHeaderCell = makeStyles(() => ({
  root: {
    color: '#555',
    textTransform: 'uppercase',
  },
}));

const HeaderCell = ({ title, accessor, sortable, handleChangePagination, paginationParams }) => {
  const classes = useStylesHeaderCell();
  const { sort_column, sort_direction } = paginationParams || {};

  const handleClick = () => {
    if (sortable) {
      const sortData = {
        sort_column: accessor,
        sort_direction: sort_direction === 'asc' ? 'desc' : 'asc'
      }

      handleChangePagination(sortData);
    }
  }

  return (
    <TableCell classes={{ root: classes.root }} component="th" scope="row">
      <TableSortLabel direction={sort_direction} active={sort_column && sort_column === accessor}
        onClick={handleClick} hideSortIcon={!sortable}>
        {title}
      </TableSortLabel>
    </TableCell>
  );
}

const THead = ({ config, pagination }) => {
  return (
    <TableHead>
      <TableRow>
        {config.map(col => (
          <HeaderCell key={col.key || col.accessor || col.title} {...col} {...pagination} />
        ))}
      </TableRow>
    </TableHead>
  )
};

const Td = ({ data, cellComponent, ...config }) => {
  return (<TableCell>{cellComponent(data, config)}</TableCell>);
}

Td.defaultProps = {
  cellComponent: function (data, { accessor }) { return data[accessor]; },
}

const TBody = ({ config, data, pending, rowKeyFn }) => {
  if (!!pending) {
    return (<TableBody><TableRow><TableCell colSpan={config.length} className="text-center"><Preloader /></TableCell></TableRow></TableBody>);
  }

  if (!data || !data.length) {
    return (<TableBody><TableRow><TableCell colSpan={config.length}>No Results</TableCell></TableRow></TableBody>);
  }

  return (
    <TableBody>
      {data.map(item => (
        <TableRow key={rowKeyFn(item)}>
          {config.map((config) => (
            <Td key={config.key || config.accessor || config.title} data={item} {...config} style={{ width: '40px', }} />
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}

const useStylesPagination = makeStyles(() => ({
  root: {
    color: '#5a5a5a',
    textTransform: 'capitalize',
  },
  selectRoot: {
    backgroundColor: '#f1f1f1',
    marginRight: 20,
  },
  actions: {
    marginLeft: 0,
  }
}));

const Pagination = ({ data, pending, pagination }) => {
  if (!pagination) {
    return null
  }

  const { paginationParams, records_total, handleChangePagination } = pagination;
  const { size_per_page, page } = paginationParams

  if (!!pending && !data || !data.length) {
    return null
  }

  const handleChangePage = (_, page) => {
    handleChangePagination({ size_per_page, page: page + 1 });
  };

  const handleChangeSizePerPage = (event) => {
    handleChangePagination({ size_per_page: event.target.value, page: 1 });
  };

  const classes = useStylesPagination();

  return (
    <TablePagination
      rowsPerPageOptions={[10, 20, 50]}
      component="div"
      count={records_total}
      rowsPerPage={size_per_page}
      page={page - 1}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeSizePerPage}
      classes={{ root: classes.root, selectRoot: classes.selectRoot, actions: classes.actions }}
    />
  )
}

TBody.defaultProps = {
  rowKeyFn: function (item) { return item.id; },
}

export const Table = (props) => {
  return (
    <TableContainer>
      <MaterialTable>
        <THead {...props} />
        <TBody {...props} />
      </MaterialTable>
      <Pagination {...props} />
    </TableContainer>
  );
}
