import { replaceItemInArray } from 'Helpers';
export function useHistory(initialState, blocked = false) {
  const [state, setState] = React.useState({ action: null, step: 0, previousStep: 0, history: initialState === undefined ? [] : [initialState], });
  function addState(newState) {
    const history = [...state.history.slice(0, state.step + 1), newState];
    const step = history.length - 1;
    setState({ history, step, previousStep: step, action: 'edit' });
  }

  function changeCurrentState(newState, action = 'edit') {
    setState({
      ...state,
      history: replaceItemInArray(state.history, { ...currentState, ...newState }, state.step),
      action,
    });
  }
  function undo(stepsCount = 1, action = 'undo') {
    if (!!blocked) {
      return
    }
    setState({ ...state, step: Math.max(state.step - stepsCount, 0), previousStep: state.step, action, });
  }

  function redo(stepsCount = 1, action = 'redo') {
    if (!!blocked) {
      return
    }
    setState({ ...state, step: Math.min(state.history.length - 1, state.step + stepsCount), previousStep: state.step, action });
  }

  const currentState = React.useMemo(function () {
    return state.history[state.step];
  }, [state.history, state.step]);

  const previousState = React.useMemo(function () {
    return state.history[state.previousStep];
  }, [state.previousStep]);

  const canUndo = React.useMemo(function () {
    return state.step > 0;
  }, [state.step]);

  const canRedo = React.useMemo(function () {
    return state.step < state.history.length - 1;
  });

  function setHistory(history, step = history.length - 1, action = 'edit') {
    setState({ ...state, history, step, action, });
  }

  return {
    ...state,
    undo,
    redo,
    currentState,
    previousState,
    canUndo,
    canRedo,
    addState,
    setHistory,
    changeCurrentState,
  };
}
