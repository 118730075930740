import { Button, makeStyles, ClickAwayListener } from '@material-ui/core';
import html2canvas from 'html2canvas';

const useStyles = makeStyles(() => ({
  canvasContainer: {
    position: 'absolute',
  },
  colorIndicator: {
    position: 'absolute',
    borderRadius: '100%',
    width: '15px',
    height: '15px',
    border: '1px solid black',
  },
}));

export const EyeDropper = ({ target, onChange }) => {
  const classes = useStyles();
  const canvasContainer = React.useRef(null);
  const colorIndicator = React.useRef(null);
  const [canvas, setCanvas] = React.useState(null);
  const [pixel, setPixel] = React.useState(null);
  function handleClick() {
    html2canvas(target).then((canvas) => {
      canvas.addEventListener('mousemove', handleCanvasMove);
      canvas.addEventListener('mouseleave', handleMouseLeave);
      canvas.addEventListener('click', handleCanvasClick);
      setCanvas(canvas);
    });
  }

  function getPixelColor(e) {
    const canv = e.target;
    const dx = canv.width / Number(canv.style.width.replace('px', ''));
    const dy = canv.height / Number(canv.style.height.replace('px', ''));
    const eventLocation = {
      x: e.offsetX * dx,
      y: e.offsetY * dy,
    };
    // const eventLocation = getEventLocation(canv, e);
    // Get the data of the pixel according to the location generate by the getEventLocation function
    const context = canv.getContext('2d');
    const [r, g, b, a] = context.getImageData(
      eventLocation.x,
      eventLocation.y,
      1,
      1
    ).data;
    return `rgba(${r},${g},${b},${a / 255})`;
  }

  function handleCanvasClick(e) {
    const color = getPixelColor(e);
    onChange(color);
    setCanvas(null);
  }

  function handleClickAway() {
    if (!!canvas) {
      setCanvas(null);
    }
  }

  React.useEffect(
    function () {
      if (!!canvas) {
        canvasContainer.current.appendChild(canvas);
      } else {
        canvasContainer.current.innerHTML = '';
        setPixel(null);
      }
    },
    [canvas]
  );

  function handleCanvasMove(e) {
    const color = getPixelColor(e);
    setPixel({
      color,
      position: {
        x: e.clientX,
        y: e.clientY,
      },
    });
  }
  function handleMouseLeave() {
    setPixel(null);
  }

  const { top, left, width, height } = target.getBoundingClientRect();

  return (
    <>
      <Button onClick={handleClick}>
        <i className="icon-eyedropper"></i>
      </Button>
      {ReactDOM.createPortal(
        <>
          <ClickAwayListener onClickAway={handleClickAway}>
            <div
              ref={canvasContainer}
              className={classes.canvasContainer}
              style={{
                top: top + window.pageYOffset,
                left: left + window.pageXOffset,
                display: canvas ? 'block' : 'none',
              }}
            ></div>
          </ClickAwayListener>
          {!!pixel && (
            <div
              ref={colorIndicator}
              className={classes.colorIndicator}
              style={{
                backgroundColor: pixel.color,
                top: `${pixel.position.y + 10}px`,
                left: `${pixel.position.x + 10}px`,
              }}
            ></div>
          )}
        </>,
        document.body
      )}
    </>
  );
};
