const PI_DEG = 180;
function sqr(a) {
  return Math.pow(a, 2);
}

/**
 * This helper uses for rotating point around another point, because Math
 * trigonometry functions works only with radians
 * This helper will use to save angle in degrees, because transform rotate
 * function get angle value in degrees;
 * @param {Number} deg angle in degrees
 * @return {Number} angle in radians
 */
export function degToRad(deg) {
  return deg * Math.PI / PI_DEG;
}

/**
 * This helper will use to save angle in degrees, because transform rotate
 * function get angle value in degrees;
 * @param {Number} rad angle in radians
 * @returns {Number} angle in degrees
 */
export function radToDeg(rad) {
  return rad * PI_DEG / Math.PI;
}

/**
 * This is simple helper just for position all slide children. We set x and y
 * as zeroes and use transform transpate to positioning item, because if we
 * will set x and y then we will have problems with rotation functionality
 * @param {Object} param0 common slide tool position: x, y, width and height
 */
export function transformRectParams({ x, y, width, height }) {
  return {
    x, y, x1: x + width, y1: y + height,
  };
}
/**
 * Check, if second rect includes or intersects with another rect
 * @param {Orect1ject} rect1
 * @param {Orect1ject} rect2
 */
export function rectsIntersection(rect1, rect2) {
  // If one rectangle is on left side of other
  if (rect1.x >= rect2.x1 || rect2.x >= rect1.x1) {
    return false;
  }

  // If one rectangle is above other
  if (rect1.y1 <= rect2.y || rect2.y1 <= rect1.y) {
    return false;
  }

  return true;

}

/**
 * Calculate angle between two vectors from same poit. using for objects rotation
 * @param {Object} intersectionPoint point of vectors intersection {x, y }
 * @param {Object} point1 point with { x, y }
 * @param {Object} point2 point with { x, y }
 */
export function getAngle(intersectionPoint, point1, point2) {
  // move two vectors to coordinates start point
  const x1 = point1.x - intersectionPoint.x;
  const y1 = point1.y - intersectionPoint.y;
  const x2 = point2.x - intersectionPoint.x;
  const y2 = point2.y - intersectionPoint.y;
  // calculate cos between vectors
  const cos = (x1 * x2 + y1 * y2) /
    (Math.sqrt(sqr(x1) + sqr(y1)) * Math.sqrt(sqr(x2) + sqr(y2)));
  const angleInDeg = radToDeg(Math.acos(cos));
  return point2.x < point1.x ? 2 * PI_DEG - angleInDeg : angleInDeg;
}

/**
 * this helper uses for rotation points around some center. also it uses in
 * resize functionality to detect resize direction when slide child is rotated
 * @param {Object} center center point of rotation
 * @param {Object} point point,which will be rotated around center
 * @param {Number} angle rotation angle
 */
export function rotatePoint(center, point, angle = 0) {
  const rad = degToRad(angle);
  const cos = Math.round(Math.cos(rad) * 1000) / 1000;
  const sin = Math.round(Math.sin(rad) * 1000) / 1000;
  const x = point.x - center.x;
  const y = point.y - center.y;
  const x1 = x * cos - y * sin;
  const y1 = x * sin + y * cos;
  return { x: x1 + center.x, y: y1 + center.y };
}
