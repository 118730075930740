import { AJAX, } from 'Helpers';
export const Form = React.forwardRef(({ name, action, method, item, children, onSubmit, disabled, onSuccessSubmit }, ref) => {
  function handleSubmit(e) {
    if (!!e && !!e.preventDefault) {
      e.preventDefault();
    }
    if (!!onSubmit) {
      onSubmit();
    }
    if (!!disabled) {
      return
    }
    const request = AJAX[method](action, {
      body: { [name]: item }
    })
    request.then((res) => !!onSuccessSubmit && onSuccessSubmit(res));
    return request;
  }

  React.useImperativeHandle(ref, function () {
    return {
      submit: handleSubmit,
    }
  });

  return (
    <form onSubmit={handleSubmit}>{children}</form>
  )
});
