import {
  Button, Dialog, DialogContent, DialogActions, Typography
} from '@material-ui/core';
import { Form, FormInput, } from 'Components';
import { makeStyles } from '@material-ui/core/styles';
import { formFactory, } from 'Helpers';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const TRIAL_MESSAGES = {
  trial_tepmlates_limit_message: 'Your trial account is limited to 5 templates. Contact sales to extend your trial or purchase Abracademo.',
  trial_demos_limit_message: 'Your trial account is limited to 10 demos. Contact sales to extend your trial or purchase Abracademo.',
  trial_users_limit_message: 'Your trial account is limited to 3 users. Contact sales to extend your trial or purchase Abracademo.',
  trial_expired: 'Your trial has expired please contact sales if you need an extension or would like to purchase Abracademo'
}

export default React.forwardRef(({ api_url }, ref) => {
  const classes = useStyles();

  const { item, changeValue, action, method, isItemInvalid,
    validationMessagesToDisplay, submit
  } = formFactory({ name: '', message: '', email: '' }, {}, api_url);


  const [dialogState, changeDialogState] = React.useState({
    isOpen: false,
    message: ''
  });

  function open(message) {
    changeDialogState({
      isOpen: true,
      message
    });
  }

  function close() {
    changeDialogState({
      ...dialogState,
      isOpen: false,
    });
  }

  function handleChangeName({ target }) {
    changeValue('name', target.value);
  }
  function handleChangeEmail({ target }) {
    changeValue('email', target.value);
  }

  function handleChangeMessage({ target }) {
    changeValue('message', target.value);
  }


  React.useImperativeHandle(ref, () => ({
    open,
  }));

  //name email message
  return (
    <Dialog open={dialogState.isOpen} fullWidth maxWidth="sm">
      <Form
        name="feedback"
        action={action}
        method={method}
        item={item}
        disabled={isItemInvalid}
        onSubmit={submit}
        onSuccessSubmit={close}
      >
        <DialogContent>
          <div className="mb-10">
            <Typography variant="h6" align="center">{TRIAL_MESSAGES[dialogState.message]}</Typography>
          </div>
          <div className="mb-10">
            <FormInput
              validationMessages={validationMessagesToDisplay.name}
              label="Name"
              value={item.name}
              onChange={handleChangeName}
            />
          </div>
          <div className="mb-10">
            <FormInput
              validationMessages={validationMessagesToDisplay.name}
              label="Email"
              value={item.email}
              onChange={handleChangeEmail}
            />
          </div>
          <div className="mb-10">
            <FormInput
              label="Message"
              multiline
              rows={6}
              value={item.message}
              onChange={handleChangeMessage}
            />
          </div>

        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Close</Button>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
});
