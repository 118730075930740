import { TREE_ASSET_ITEM_TYPES, TREE_DEMO_ITEM_TYPES, ASSET_FILE_TYPES, IMAGE_FILE_TYPES, FILE_TYPES } from 'Constants';

/**
 * helper returns id wich needed for ability to create children of item
 * @param {Object} item
 */
export function getParentIdByAssetType({ type, id, parentId }) {
  return [TREE_ASSET_ITEM_TYPES.image, TREE_ASSET_ITEM_TYPES.audio].indexOf(type) > -1 ? parentId : id
}

export function getParentIdByDemoType({ type, id, parentId }) {
  return type === TREE_DEMO_ITEM_TYPES.demo ? parentId : id
}

/**
 * helper returns true if all files has image or audio type
 * @param {Object} files
 */
export function isCorrectFilesType(files) {
  return Object.values(files).every((file) => {
    return ASSET_FILE_TYPES.some(type => file.type.includes(type))
  });
}

/**
 * helper returns true if all files has image type
 * @param {Object} files
 */
export function isCorrectImageFilesType(files) {
  return Object.values(files).every((file) => {
    return IMAGE_FILE_TYPES.some(type => file.type.includes(type))
  });
}

export function getAssetTypeByFile(fileType) {
  for (let item of Object.keys(FILE_TYPES)) {
    if (FILE_TYPES[item].some(type => fileType.includes(type))) {
      return item
    }
  }
}
