function zerofyFormat(number) {
  return (number > 9 ? '' : '0') + number
}

export function dateToFetchStringFormat(date) {
  const yyyy = date.getFullYear();
  const mm = date.getMonth() + 1; // getMonth() is zero based
  const dd = date.getDate();

  return `${yyyy}-${zerofyFormat(mm)}-${zerofyFormat(dd)}`; //EX: 2021-03-24
}

export function dateToDisplayFormat(date) {
  const year = date.getFullYear();
  const month = date.toLocaleString('en-us', { month: "short" });
  const day = date.getDate();

  return `${month} ${zerofyFormat(day)} ${year}`; //EX: Mar 01 2021
}
