import { setObjectValue, validate, } from 'Helpers';

export function formValueFactory(initialValue = {}) {
  const [item, changeItem] = React.useState(initialValue);

  function changeValue(key, value) {
    const newItem = setObjectValue(item, key, value);
    changeItem(newItem);
  }

  return [item, changeValue, changeItem];
}

export function formSubmitFactory() {
  const [submitted, changeSubmitted,] = React.useState(false);

  function submit() {
    changeSubmitted(true);
  }

  function unsubmit() {
    changeSubmitted(false);
  }
  return { submitted, submit, unsubmit };
}

export function validationFactory(item, validators, formSubmitted) {
  const validationMessages = React.useMemo(function () {
    return validate(item, validators);
  }, [item]);

  const isItemInvalid = React.useMemo(function () {
    return !!Object.keys(validationMessages).length;
  }, [validationMessages]);

  const validationMessagesToDisplay = React.useMemo(function () {
    return formSubmitted ? validationMessages : {};
  });

  return { validationMessages, isItemInvalid, validationMessagesToDisplay, };
}

export function formFactory(initialItem, validators, url) {
  const [item, changeValue, changeItem] = formValueFactory(initialItem);
  const method = React.useMemo(function() { return item.id ? 'put' : 'post'; }, [item]);
  const action = React.useMemo(function() { return item.id ? `${url}/${item.id}` : url; }, [item]);
  const submit = formSubmitFactory();
  const validation = validationFactory(item, validators, submit.submitted);
  return { action, method, item, changeValue, changeItem, ...submit, ...validation, };
}
