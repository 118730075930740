import { createMuiTheme } from '@material-ui/core/styles';

export const DarkTheme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

export const WhiteTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#60CAE6',
      contrastText: '#fff',
    },

  },
});
