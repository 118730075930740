import { Container, Button, Icon, Card, CardContent, Typography, Grid, makeStyles, } from "@material-ui/core";
import { PageTitle } from "Components";

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: '0!important',
    paddingTop: '0!important',
  },
}));

export const ListWrapper = ({ title, children, headerAddon }) => {
  const classes = useStyles();

  return (
    <Container>
      <div className="display-flex align-center justify-between">
        <PageTitle title={title} />
        {headerAddon}
      </div>

      <Card>
        <CardContent classes={{ root: classes.root }} >
          {children}
        </CardContent>
      </Card>
    </Container>
  )
};

export const FormWrapper = ({ title, children, item, formSubmitBtn }) => {
  const submitBtn = formSubmitBtn ? formSubmitBtn : (
    <Button variant="contained" color="primary" type="submit" endIcon={<Icon>send</Icon>}>
      {item.id ? 'Update' : 'Create'} {title}
    </Button>
  );
  const formTitle = !!item.id ? `Edit ${title}` : `New ${title}`;

  return (
    <Container maxWidth="md">
      <div className="display-flex align-center justify-between">
        <PageTitle title={formTitle} />
        {submitBtn}
      </div>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            {children}
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
};
