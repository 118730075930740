import { IconButton, Button, Icon, Dialog, DialogTitle, DialogContent, DialogActions, } from '@material-ui/core';
import { AJAX } from 'Helpers';

export const DeleteConfirm = ({ deleteUrl, onConfirm }) => {
  const [isOpen, changeIsOpen] = React.useState(false);
  function open() {
    changeIsOpen(true);
  }

  function close() {
    changeIsOpen(false);
  }
  function handleConfirm() {
    AJAX.delete(deleteUrl).then(function () {
      close();
      onConfirm();
    });
  }

  return (
    <>
      <IconButton onClick={open}>
        <Icon>delete</Icon>
      </IconButton>
      <Dialog open={isOpen}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>Are you sure?</DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
