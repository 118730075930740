
function getItemSize(ref) {
  if (!ref.current) {
    return { width: 0, height: 0, };
  }
  const { width, height, } = ref.current.getBoundingClientRect();
  return { width, height, };
}

export function useResize(ref) {
  const [size, setSize] = React.useState(null);

  function updateSize() {
    setSize(getItemSize(ref));
  }


  React.useLayoutEffect(() => {
    if (!ref.current) {
      return
    }

    updateSize()

    // for browser supporting
    if (typeof ResizeObserver === 'function') {
      let resizeObserver = new ResizeObserver(() => updateSize())
      resizeObserver.observe(ref.current)

      return () => {
        if (!resizeObserver) {
          return
        }

        resizeObserver.disconnect()
        resizeObserver = null
      }
    } else {
      window.addEventListener('resize', updateSize)

      return () => {
        window.removeEventListener('resize', updateSize)
      }
    }
  }, [ref.current])


  return { canvasContainerSize: size, updateSize };
}
