export const AUTOSAVE_TIMEOUT = 10000;
export const ROTATION_TOOL_SIZE = 5;
export const TOOLS_WRAPPER_PADDING = 10;
export const RESIZE_TOOL_SIZE = 5;
export const ROTATION_TOOL_MARGIN = 20;
export const RESIZE_TOOLS_CURSORS = [
  'nwse-resize',
  'ns-resize',
  'nesw-resize',
  'ew-resize',
  'nwse-resize',
  'ns-resize',
  'nesw-resize',
  'ew-resize',
];
export const RESIZE_TOOLS_DIRECTIONS = [
  'top_left',
  'top',
  'top_right',
  'right',
  'bottom_right',
  'bottom',
  'bottom_left',
  'left',
];
export const TEXT_BLOCK_TYPE = 'TextBlock';
export const HOTSPOT_TYPE = 'Hotspot';
export const ASSET_TYPE = 'Asset';
export const DEVICE_TYPE = 'Device';
export const BUTTON_TYPE = 'Button';
export const LINK_TYPE = 'Link';
export const RECT_TYPE = 'Rect';
export const ELLIPSE_TYPE = 'Ellipse';
export const LINE_TYPE = 'Line';
export const ARROW_TYPE = 'Arrow';
export const TRIANGLE_TYPE = 'Triangle';
export const INPUT_TYPE = 'Input';
export const TEXTAREA_TYPE = 'Textarea';
export const LOCK_TYPE = 'Lock';
export const GROUP_TYPE = 'Group';
export const COPY_TYPE = 'Copy';
export const CUT_TYPE = 'Cut';
export const PASTE_TYPE = 'Paste';
export const DUPLICATE_TYPE = 'Duplicate';
export const UNDO_TYPE = 'Undo';
export const REDO_TYPE = 'Redo';
export const SELECT_ALL_TYPE = 'Select All';
export const SAVE_TYPE = 'Save';
export const SAVE_AS_TYPE = 'SaveAs';
export const PRESENT_TYPE = 'Present';
export const DUPLICATE_SLIDE_TYPE = 'DuplicateSlide';
export const DELETE_TYPE = 'Delete';
export const NEW_TEMPLATE_TYPE = 'New';
export const RADIO_TYPE = 'Radio';
export const CHECKBOX_TYPE = 'Checkbox';
export const SELECT_TYPE = 'Select';
export const BROWSER_WINDOW_TYPE = 'BrowserWindow';
export const BRING_FORWARD_TYPE = 'Bring Forward';
export const SEND_BACKWARD_TYPE = 'Send Backward';
export const BRING_TO_FRONT_TYPE = 'Bring to Front';
export const SEND_TO_BACK_TYPE = 'Send to Back';
export const ZOOM_IN_TYPE = 'ZoomIn';
export const ZOOM_OUT_TYPE = 'ZoomOut';
export const CANCEL_TYPE = 'Cancel';

export const GUIDELINE_TYPE = 'Guideline';
export const SHOW_GUIDELINES_TYPE = 'ShowGuidelines';
export const CONTENT_EDITABLE_TYPE = 'Contenteditable';
export const AUDIO_TYPE = 'Audio';

export const IMAGE_TOOLS = [
  DEVICE_TYPE,
  ASSET_TYPE,
]

export const ITEMS_TOOLS_LIST = [
  {
    name: BUTTON_TYPE,
    tooltip: BUTTON_TYPE,
    icon: 'button',
  },
  {
    name: LINK_TYPE,
    tooltip: LINK_TYPE,
    icon: 'connection',
  },
  {
    name: INPUT_TYPE,
    tooltip: INPUT_TYPE,
    icon: 'input',
  },
  {
    name: TEXTAREA_TYPE,
    tooltip: 'Text Area',
    icon: 'input',
  },
  {
    name: RADIO_TYPE,
    tooltip: RADIO_TYPE,
    icon: 'radio',
  },
  {
    name: CHECKBOX_TYPE,
    tooltip: CHECKBOX_TYPE,
    icon: 'checkbox',
  },
  {
    name: SELECT_TYPE,
    tooltip: SELECT_TYPE,
    icon: 'select',
  },
  {
    name: HOTSPOT_TYPE,
    tooltip: HOTSPOT_TYPE,
    icon: 'hotspot',
  },
];

export const GUIDELINE_TOOL = {
  name: GUIDELINE_TYPE,
  tooltip: 'Guides',
  icon: 'guide',
};

export const ITEMS_SHAPES_LIST = [
  {
    name: RECT_TYPE,
    tooltip: 'Rectangle',
    icon: 'button',
  },
  {
    name: ELLIPSE_TYPE,
    tooltip: ELLIPSE_TYPE,
    icon: 'ellipse',
  },
  {
    name: ARROW_TYPE,
    tooltip: ARROW_TYPE,
    icon: 'next',
  },
  {
    name: TRIANGLE_TYPE,
    tooltip: TRIANGLE_TYPE,
    icon: 'bleach',
  },
  {
    name: LINE_TYPE,
    tooltip: LINE_TYPE,
    icon: 'minus',
  },
];

export const TOOLS_TAGS_NAMES = {
  [BUTTON_TYPE]: 'a',
  [LINE_TYPE]: 'a',
  [TEXT_BLOCK_TYPE]: 'div',
};

export const ITEMS_TOOLS = [
  TEXT_BLOCK_TYPE,
  BUTTON_TYPE,
  LINK_TYPE,
  INPUT_TYPE,
  TEXTAREA_TYPE,
  RADIO_TYPE,
  CHECKBOX_TYPE,
  SELECT_TYPE,
  GUIDELINE_TYPE,
];
export const TOOLS_WITH_FONT = [
  TEXT_BLOCK_TYPE,
  BUTTON_TYPE,
  LINK_TYPE,
  INPUT_TYPE,
  TEXTAREA_TYPE,
  RADIO_TYPE,
  CHECKBOX_TYPE,
  SELECT_TYPE,
  GUIDELINE_TYPE,
];
export const SHAPE_TOOLS = [
  HOTSPOT_TYPE,
  RECT_TYPE,
  ELLIPSE_TYPE,
  ARROW_TYPE,
  TRIANGLE_TYPE,
  LINE_TYPE,
];

export const LINK_TOOLS = [
  HOTSPOT_TYPE,
  LINK_TYPE,
  BUTTON_TYPE,
  RADIO_TYPE,
  CHECKBOX_TYPE,
];

export const SLIDE_LINK_TOOLS = [
  HOTSPOT_TYPE,
  LINK_TYPE,
  BUTTON_TYPE,
];

export const ANIMATED_LINK_TOOLS = [
  LINK_TYPE,
  BUTTON_TYPE,
];

export const TEXT_TOOLS = [
  TEXT_BLOCK_TYPE,
  BUTTON_TYPE,
  LINK_TYPE,
  GUIDELINE_TYPE,
  RADIO_TYPE,
  CHECKBOX_TYPE,
];
export const TOOLS_WITHOUT_BORDER_RADIUS = [
  LINE_TYPE,
  ARROW_TYPE,
  TRIANGLE_TYPE,
  ELLIPSE_TYPE,
];
export const TOOLS_WITH_BACKGROUND = [
  BUTTON_TYPE,
  RECT_TYPE,
  ELLIPSE_TYPE,
  ARROW_TYPE,
  TRIANGLE_TYPE,
  GUIDELINE_TYPE,
  INPUT_TYPE,
  TEXTAREA_TYPE,
  SELECT_TYPE,
];

export const COLOR_TOOLS_LIST = [
  {
    icon: 'text_color',
    keyName: 'textColor',
    tooltip: 'Text Color',
    toolsToEdit: TEXT_TOOLS,
  },
  {
    icon: 'paint',
    keyName: 'backgroundColor',
    tooltip: 'Fill',
    toolsToEdit: TOOLS_WITH_BACKGROUND,
  },
];

export const SHAPES_TOOL_ICON = 'format_shapes';
export const DEVICES_TOOL_ICON = 'video_label';
export const TOOLS_WITHOUT_RESIZE = [GROUP_TYPE];
export const TOOLS_WITHOUT_ROTATE = [DEVICE_TYPE];

export const ALL_TOOLS_TYPES = [
  ...ITEMS_TOOLS,
  ...SHAPE_TOOLS,
  UNDO_TYPE,
  REDO_TYPE,
  COPY_TYPE,
  CUT_TYPE,
  PASTE_TYPE,
  DUPLICATE_TYPE,
  LOCK_TYPE,
  GROUP_TYPE,
  SELECT_ALL_TYPE,
  SAVE_TYPE,
  SAVE_AS_TYPE,
  DELETE_TYPE,
  BRING_FORWARD_TYPE,
  SEND_BACKWARD_TYPE,
  BRING_TO_FRONT_TYPE,
  SEND_TO_BACK_TYPE,
  ZOOM_IN_TYPE,
  ZOOM_OUT_TYPE,
  CANCEL_TYPE,
];

export const DEVICE_TOOL = {
  name: DEVICE_TYPE,
  tooltip: 'Devices',
  icon: 'important_devices',
};

export const TOOLS_TEXT = {
  [SELECT_ALL_TYPE]: 'Select All',
  [SAVE_AS_TYPE]: 'Save As',
  [DUPLICATE_SLIDE_TYPE]: 'Duplicate Slide',
  [DELETE_TYPE]: 'Delete',
};

export const LOCK_TOOL = {
  icon: 'lock',
  tooltip: 'Lock',
};

export const LOCK_ALL_TOOL = {
  icon: 'lock',
  tooltip: 'Lock All',
};

export const UNLOCK_ALL_TOOL = {
  icon: 'unlock',
  tooltip: 'Unlock All',
};

export const GROUP_TOOL = {
  icon: 'group',
  tooltip: 'Group',
};

export const SHOW_GUIDELINES_TOOL = {
  type: SHOW_GUIDELINES_TYPE,
  tooltip: 'Show Guides',
  icon: 'guide',
};

export const UNDO_TOOL = {
  type: UNDO_TYPE,
  tooltip: UNDO_TYPE,
  icon: 'undo',
};

export const REDO_TOOL = {
  type: REDO_TYPE,
  tooltip: REDO_TYPE,
  icon: 'redo',
};

export const TOOLS_KEYS_MAP = {
  ctrl: {
    s: SAVE_TYPE,
    S: SAVE_AS_TYPE,
    g: GROUP_TYPE,
    c: COPY_TYPE,
    x: CUT_TYPE,
    v: PASTE_TYPE,
    d: DUPLICATE_TYPE,
    z: UNDO_TYPE,
    y: REDO_TYPE,
    Z: REDO_TYPE,
    a: SELECT_ALL_TYPE,
    F5: PRESENT_TYPE,
    n: NEW_TEMPLATE_TYPE,
    '+': ZOOM_IN_TYPE,
    '=': ZOOM_IN_TYPE,
    '-': ZOOM_OUT_TYPE,
  },
  ctrlWithShift: {
    z: REDO_TYPE,
  },
  Delete: DELETE_TYPE,
  Backspace: DELETE_TYPE,
  Escape: CANCEL_TYPE,
};

export const PREVENT_RATIO_KEY = 'Shift';

export const COPY_TOOL = {
  type: COPY_TYPE,
  tooltip: COPY_TYPE,
  icon: 'copy',
};

export const PASTE_TOOL = {
  type: PASTE_TYPE,
  tooltip: PASTE_TYPE,
  icon: 'paste',
};

export const ASSET_TOOL = {
  name: ASSET_TYPE,
  tooltip: 'Library',
  icon: 'assets',
};

export const ZOOM_IN_TOOL = {
  name: ZOOM_IN_TYPE,
  tooltip: 'Zoom In',
  icon: 'zoom_in',
};

export const ZOOM_OUT_TOOL = {
  name: ZOOM_OUT_TYPE,
  tooltip: 'Zoom Out',
  icon: 'zoom_out',
};

export const TEXT_BLOCK_TOOL = {
  name: TEXT_BLOCK_TYPE,
  tooltip: 'Text',
  icon: 'text_field',
};

export const DEFAULT_ZOOM_FACTOR = 1;
export const MAX_ZOOM_FACTOR = 2;
export const MIN_ZOOM_FACTOR = 0.5;

export const SETTINGS_TOOL = {
  tooltip: 'Settings',
  icon: 'settings',
};

export const SHARE_TOOL = {
  tooltip: 'Share',
  icon: 'share',
};

export const SAVE_TOOL = {
  tooltip: 'Save',
  icon: 'save',
};

export const PREVIEW_TOOL = {
  tooltip: 'Present',
  icon: 'preview',
};

export const AUDIO_TOOL = {
  name: AUDIO_TYPE,
  tooltip: 'Audio',
};

export const ZOOM_TOOL_TYPES = [ZOOM_IN_TYPE, ZOOM_OUT_TYPE];
export const INPUT_TYPES = [INPUT_TYPE, TEXTAREA_TYPE, SELECT_TYPE];
export const INPUT_WITH_LABEL = [CHECKBOX_TYPE, RADIO_TYPE];

export const DEFAULT_FONT_WEIGHT = 400;
export const BOLD_FONT_WEIGHT = 900;
export const DEFAULT_TEXT_DECORATION = 'auto';
export const UNDERLINED_TEXT_DECORATION = 'underline';
export const DEFAULT_FONT_STYLE = 'normal';
export const ITALIC_FONT_STYLE = 'italic';
export const DEFAULT_FONT_SIZE = 19;
export const DEFAULT_TOOL_WIDTH = 150;
export const DEFAULT_TOOL_HEIGHT = 30;
export const DEFAULT_GUIDLINE_TOOL_HEIGHT = 60;

export const FONT_SIZES = Array.from(Array(90).keys()).map((key) => {
  return key + 6
});
export const INPUT_WIDTH_LABEL_WIDTH = 19;

export const INPUT_RATIO_SCALE_FACTOR = 1.5;

export const DEFAULT_CROP = {
  unit: '%',
  width: 100,
  height: 100,
};

export const FONTS = [
  { id: 'Roboto', name: 'Roboto' },
  { id: 'cursive', name: 'Cursive' },
  { id: 'fantasy', name: 'Fantasy' },
  { id: 'monospace', name: 'Monospace' },
];

export const CANVAS_SIZE = {
  width: 1200,
  height: 675,
}

export const MAX_IMAGE_SIZE = {
  width: CANVAS_SIZE.width * 0.7,
  height: CANVAS_SIZE.height * 0.7,
}

export const NAVIGATION_CANVAS_SIZE = {
  width: 165,
  height: 93.69,
}

export const SELECT_STYLES = {
  arrow: {
    size: 5
  },
  label: {
    padding: 20
  },
  text: {
    padding: 5
  },
  dropdownOption: {
    padding: 5
  },
}

export const SLIDE_HOTSPOT_TYPE = 'slide';
export const INTERNET_LINK = 'internetLink';

export const HOTSPOT_TYPES = [
  { id: SLIDE_HOTSPOT_TYPE, name: 'Go to Slide Number' },
  { id: INTERNET_LINK, name: 'Go to URL' }
];

export const QUERY_CURRENT_SLIDE_ID = "current_slide_id"
