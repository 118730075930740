import { IconButton, Icon } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { DeleteConfirm } from 'Components';

export const TemplateActions = ({
  template,
  newDemoUrl,
  templatesUrl,
  fetchUrl,
  fetchData,
  showButtons,
  iconsSize,
  onTemplateSettingsClick,
}) => {
  const { id } = template;
  const template_url = `${templatesUrl}/${id}`;
  const { show_create_demo, show_settings, show_preview, show_edit, show_delete } = showButtons;

  return (
    <div className={classnames('actions-block', { 'actions-block--small-icons': iconsSize == 'small' })}>
      {
        !!show_create_demo && <Tooltip title="Create Demo" placement="top">
          <IconButton href={`${newDemoUrl}?template_id=${id}`}>
            <Icon>dvr</Icon>
          </IconButton>
        </Tooltip>
      }
      {
        !!show_settings && <IconButton onClick={() => onTemplateSettingsClick(template)}>
          <Icon>settings</Icon>
        </IconButton>
      }
      {
        !!show_preview && <IconButton href={template_url}>
          <Icon>visibility</Icon>
        </IconButton>
      }
      {
        !!show_edit && <IconButton href={`${template_url}/edit`}>
          <Icon>edit</Icon>
        </IconButton>
      }
      {
        !!show_delete && <DeleteConfirm deleteUrl={`${fetchUrl}/${id}`} onConfirm={fetchData} />
      }
    </div>
  )
}
