import {
  FormControl, FormLabel, FormControlLabel, RadioGroup as RGroup,
  FormHelperText, Radio
} from '@material-ui/core';

export const RadioGroup = ({ validationMessages, options, onChange, value, label, disabled }) => (
  <FormControl component="fieldset" error={!!validationMessages}>
    <FormLabel component="legend">{label}</FormLabel>
    <RGroup value={value} onChange={onChange}>
      {options.map(({ id, name }) => (
        <FormControlLabel key={id} value={id} control={<Radio disabled={disabled} />} label={name} />
      ))}
    </RGroup>
    <FormHelperText>{validationMessages}</FormHelperText>
  </FormControl>
);

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
  })),
}
