import { makeStyles } from '@material-ui/core/styles';
import {
  Icon,
  Button,
  Divider,
  ListItemText,
  ListItemIcon,
  IconButton,
  MenuItem,
  Box,
} from '@material-ui/core';

import { ButtonWithMenu, AlertNotification } from 'Components';
import { getMetaTagValue, AJAX } from 'Helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    color: '#fff',
    borderColor: '#fff',
  },
  menuIcon: {},
  menuCloseButton: {
    color: '#fff',
  },
  title: {
    flexGrow: 1,
  },
  dropdownListIcon: {
    minWidth: '32px',
  },
  userName: {
    fontSize: '0.775rem',
    display: 'block',
    textTransform: 'capitalize',
    textAlign: 'left',
  },
  userEmail: {
    fontSize: '0.575rem',
    display: 'block',
    textTransform: 'none',
    textAlign: 'left',
  },
  avatar: {
    backgroundColor: '#dcd6f7',
  },
  dropdownMenuSettings: {
    width: '100%',
  },
  alertNotificationWrapper: {
    paddingTop: '15px'
  },
  confirmationText: {
    marginRight: '20px'
  },
}));

export const MenuAppBar = ({
  user_navigation,
  logout_url,
  application_navigation,
  company_sub_navigation,
  title,
  user,
  logo_url,
  dashboard_url,
  company_dashboard_url,
  tutorial_link,
}) => {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = React.useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const [isConfirmationFetching, setIsConfirmationFetching] = React.useState(false);

  const handleSendConfirmation = async () => {
    setIsConfirmationFetching(true)
    await AJAX.post(user.request_confirmation_url)
    setIsConfirmationFetching(false)
  }

  return (
    <>
      <div className={classes.root}>
        <div className="header-wrapper">
          <div className="header header-wrapper__row">
            <div className="header__logo">
              <a href={dashboard_url}>
                <img
                  src={logo_url}
                  className="header__logo-img"
                  alt="Abracademo logo"
                />
              </a>
            </div>
            {!!title && (
              <a className="header__link" href={company_dashboard_url}>
                <h5 className="header__campany-name">{title}</h5>
              </a>
            )}
            <div className="header-actions header__actions">
              <span className="header-actions__name">{user.name}</span>
              <ButtonWithMenu
                menuPaperStyle={{ width: '200px' }}
                trigger={
                  <IconButton className={classes.menuButton}>
                    <Icon>settings</Icon>
                  </IconButton>
                }
              >
                {user_navigation.map(({ href, text, icon }, index) => (
                  <MenuItem key={href + index} component="a" href={href}>
                    {!!icon && (
                      <ListItemIcon className={classes.dropdownListIcon}>
                        <Icon>{icon}</Icon>
                      </ListItemIcon>
                    )}
                    <ListItemText primary={text} />
                  </MenuItem>
                ))}
                <Divider />
                <form action={logout_url} method="post" key={'form-logout'}>
                  <input
                    type="hidden"
                    name="authenticity_token"
                    value={getMetaTagValue('csrf-token')}
                  />
                  <input type="hidden" name="_method" value="DELETE" />
                  <MenuItem
                    component="button"
                    type="submit"
                    className={classes.dropdownMenuSettings}
                  >
                    <ListItemIcon className={classes.dropdownListIcon}>
                      <Icon>exit_to_app</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Log Out" />
                  </MenuItem>
                </form>
              </ButtonWithMenu>
              <IconButton className={classes.menuButton} onClick={toggleMenu}>
                <Icon>menu</Icon>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      {user.require_confirmation && (
        <Box className={classes.alertNotificationWrapper}>
          <AlertNotification type='warning' title={(
            <>
              <span className={classes.confirmationText}>
                Your email address has not been verified
              </span>
              <Button variant="outlined" color="inherit" size="small" onClick={handleSendConfirmation}
                disabled={isConfirmationFetching}>
                Re-send Email Verification
              </Button>
            </>
          )} />
        </Box>
      )}

      <div
        className={classnames('side-menu', { 'side-menu--visible': openMenu })}
      >
        <div className="side-menu__close-btn">
          <IconButton className={classes.menuCloseButton} onClick={toggleMenu}>
            <Icon>close</Icon>
          </IconButton>
        </div>
        <ul className="side-menu-list">
          {application_navigation
            .filter(({ visible }) => !!visible)
            .map(({ href, text, active, icon }, index) => (
              <li key={`${href}${index}`} className="side-menu-list__item">
                <a
                  href={href}
                  className={classnames('side-menu-list__link', {
                    'side-menu-list__link--active': active,
                  })}
                >
                  <i className={classnames('menu-icon', `icon-${icon}`)}></i>
                  {text}
                </a>
              </li>
            ))}
          {!!company_sub_navigation &&
            company_sub_navigation
              .filter(({ visible }) => !!visible)
              .map(({ href, text, active, icon }, index) => (
                <li key={`${href}${index}`} className="side-menu-list__item">
                  <a
                    href={href}
                    className={classnames('side-menu-list__link', {
                      'side-menu-list__link--active': active,
                    })}
                  >
                    <i className={classnames('menu-icon', `icon-${icon}`)}></i>
                    {text}
                  </a>
                </li>
              ))}
          <li className="side-menu-list__item">
            <a
              href={tutorial_link.href}
              className={classnames('side-menu-list__link', {
                'side-menu-list__link--active': tutorial_link.active,
              })}
            >
              <i
                className={classnames(
                  'menu-icon',
                  `icon-${tutorial_link.icon}`
                )}
              ></i>
              {tutorial_link.text}
            </a>
          </li>
        </ul>
      </div>
      <div
        className={classnames('backdrop', { 'backdrop--visible': openMenu })}
        onClick={toggleMenu}
      ></div>
    </>
  );
};
