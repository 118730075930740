import { AJAX } from 'Helpers';

function showServerErrors(messages) {
  const msgWrapper = document.getElementById('msg');
  const messageToDisplay = Object.keys(messages).reduce((acc, item) => {
    return `
      ${acc}
      <p class="msg__error">${item.replace('_', ' ')}: ${messages[item].join(',')}.<p>
    `
  }, ``)
  msgWrapper.innerHTML = messageToDisplay;

}

window.recaptchaOnSuccess = function () {
  document.getElementById('submit-btn').disabled = false;
};

window.recaptchaOnError = function () {
  document.getElementById('submit-btn').disabled = true;
};

function getEmtyFieldNames(values) {
  return Object.keys(values).filter(
    (key) => !values[key]
  );
}

window.addEventListener('load', function () {
  const form = document.getElementById('sign-up-form');
  if (!form) {
    return;
  }

  form.addEventListener('submit', function (e) {
    e.preventDefault();
    e.stopPropagation();

    const fields = document.getElementsByClassName('sign-up-input');
    const url = form.getAttribute('action');
    const token = document
      .querySelector('[name="authenticity_token"]')
      .getAttribute('value');
    const values = Object.values(fields).reduce(
      (res, { name, value }) => ({ ...res, [name]: value }),
      {}
    );

    const emptyFields = getEmtyFieldNames(values)

    if (!!emptyFields.length) {
      const errorsData = emptyFields.reduce((acc, item) => {
        return {
          ...acc,
          [item]: ['can\'t be blank']
        }
      }, {})

      return showServerErrors(errorsData);
    }

    const loader = document.getElementById('modal-form-loader')
    document.getElementById('submit-btn').disabled = true;
    loader.classList.remove('modal-form-loader--hidden');

    AJAX.post(url, {
      headers: {
        Accept: 'application/json',
        ['Content-Type']: 'application/json',
        ['X-CSRF-Token']: token,
      },
      body: {
        user: values,
      },
    }).then(function (res) {
      if (res.status === "error") {
        showServerErrors(res.errors);
      }
      loader.classList.add('modal-form-loader--hidden')
      document.getElementById('submit-btn').disabled = false;
    });
  });

  $('#signUpModal').on('hidden.bs.modal', function () {
    document.getElementById('msg').innerHTML = '';
  });
});
