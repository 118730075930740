import { GROUP_TYPE, IMAGE_TOOLS, SLIDE_LINK_TOOLS, SLIDE_HOTSPOT_TYPE } from 'Constants';
import { findInArrayBy } from 'Helpers';

function getSlideImagesUrls(children) {
  return children.reduce(function (res, child) {
    if (child.type === GROUP_TYPE) {
      return getSlideImagesUrls(child.data.children)
    }
    else if (IMAGE_TOOLS.indexOf(child.type) > -1) {
      return [
        ...res,
        child.src
      ]
    }
    else {
      return [...res]
    }
  }, []);
}

const loadSlideImages = (bindedSlideImages, index = 0) => {
  if (index >= bindedSlideImages.length || !bindedSlideImages[index]) {
    return
  }

  const currentImagesData = bindedSlideImages[index];

  const onLoad = () => {
    currentImagesData.remaining -= 1;
    if (currentImagesData.remaining === 0) {
      loadSlideImages(bindedSlideImages, index + 1)
    }
  }

  currentImagesData.urls.forEach((url) => {
    const img = new Image();
    img.src = url;
    img.onload = onLoad;
    img.onerror = onLoad;
  })
}

const getBindedSlidesByCurrentSlideLinks = (children, slides) => {
  return children.reduce((res, child) => {
    if (child.type === GROUP_TYPE) {
      return getBindedSlidesByCurrentSlideLinks(child.data.children, slides)
    }

    else if (SLIDE_LINK_TOOLS.indexOf(child.type) > -1 && child.data.type === SLIDE_HOTSPOT_TYPE && !!child.data.href) {
      const slide = findInArrayBy(slides, child.data.href, 'identifier');

      return {
        ...res,
        ...(!!slide && { [slide.identifier]: slide })
      }
    }

    else {
      return {
        ...res
      }
    }
  }, {});
}


export function useImagesLoader(slides, currentIndex) {

  const firstSlideImagesUrls = React.useMemo(() => {
    return getSlideImagesUrls(slides[0].data.children)
  }, []);

  const bindedSlideImages = React.useMemo(() => {
    const bindedSlides = getBindedSlidesByCurrentSlideLinks(slides[currentIndex].data.children, slides);

    return Object.keys(bindedSlides).reduce((res, slide_id) => {
      const slideImagesUrls = getSlideImagesUrls(bindedSlides[slide_id].data.children);
      if (slideImagesUrls.length) {
        return [
          ...res,
          {
            urls: slideImagesUrls,
            remaining: slideImagesUrls.length
          }
        ]
      }
      return [
        ...res,
      ]
    }, []);
  }, [currentIndex]);

  const [firstSlideRemainingImgCount, changeFirstSlideRemainingImgCount] = React.useState(firstSlideImagesUrls.length);

  const decreaseFirstSlideRemainingImgCount = () => {
    changeFirstSlideRemainingImgCount((currentState) => {
      return currentState - 1
    });
  }

  React.useEffect(() => {
    firstSlideImagesUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
      img.onload = decreaseFirstSlideRemainingImgCount;
      img.onerror = decreaseFirstSlideRemainingImgCount;
    });
  }, []);

  React.useEffect(() => {
    if (firstSlideRemainingImgCount === 0) {
      loadSlideImages(bindedSlideImages)
    }
  }, [!!firstSlideRemainingImgCount]);


  React.useEffect(() => {
    if (currentIndex === 0) {
      return
    }

    loadSlideImages(bindedSlideImages);
  }, [currentIndex]);

  return { firstSlideImagesLoaded: firstSlideRemainingImgCount == 0 }
}
