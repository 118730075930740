export function pushToArray(arr, item) {
  return [...arr, item];
}

export function findInArrayBy(arr, val, key = 'id') {
  return arr.find(function (item) {
    return item[key] == val;
  });
}

export function findIndexInArrayBy(arr, val, key = 'id') {
  return arr.findIndex(function (item) {
    return item[key] == val;
  });
}

export function insertItemToArray(arr, item, index) {
  return [
    ...arr.slice(0, index),
    item,
    ...arr.slice(index + 1),
  ];
}

export function replaceItemInArray(arr, item, index) {
  return [
    ...arr.slice(0, index),
    item,
    ...arr.slice(index + 1),
  ];
}

export function removeFromArray(arr, val, key = 'id') {
  const index = findIndexInArrayBy(arr, val, key);
  if (index === -1) {
    return arr;
  }
  return [
    ...arr.slice(0, index),
    ...arr.slice(index + 1),
  ];
}

export function updateItemInArray(arr, item, val, key = 'id') {
  const index = findIndexInArrayBy(arr, val, key);

  if (index === -1) {
    return pushToArray(arr, item);
  }

  return [
    ...arr.slice(0, index),
    item,
    ...arr.slice(index + 1),
  ];
}

export function isArray(obj) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(obj) === '[object Array]';
  };
  return Array.isArray(obj);
}

/**
 * Function move item inside array
 * @param {Array} src array of items, where item should be moved
 * @param {Number} from idex of item, which should be moved
 * @param {Number} to new item position
 * @returns {Array} new array of items
 */
export function moveItemInArray(src, from, to) {
  const item = src[from];
  if (to > from) {
    return [
      ...src.slice(0, from),
      ...src.slice(from + 1, to + 1),
      item,
      ...src.slice(to + 1),
    ];
  }

  if (from > to) {
    return [
      ...src.slice(0, to),
      item,
      ...src.slice(to, from),
      ...src.slice(from + 1),
    ];
  }
  return src;
}
