export const PLAYER_EVENTS = {
  TIME_UPDATE: 'timeupdate',
  CAN_PLAY: 'canplaythrough',
  DURATION_CHANGE: 'durationchange',
  ENDED: 'ended'
};

export const PLAYER_STATUSES = {
  PLAY: 'play',
  PAUSE: 'pause',
  MUTE: 'mute',
  UNMUTE: 'unmute',
}