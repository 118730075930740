export * from './builder';

export const Codes = {
  ENTER: 13,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
}

export const TREE_WRAPPER = 'root';



export const IMAGE_FILE_TYPES = ['jpg', 'jpeg', 'png'];
export const AUDIO_FILE_TYPES = ['audio/mpeg'];
export const ASSET_FILE_TYPES = [...IMAGE_FILE_TYPES, ...AUDIO_FILE_TYPES];

export const ASSET_TYPES = {
  folder: 'folder',
  image: 'image',
  audio: 'audio'
}

export const TREE_ASSET_ITEM_TYPES = {
  [ASSET_TYPES.folder]: 'Asset::Folder',
  [ASSET_TYPES.image]: 'Asset::Screenshot',
  [ASSET_TYPES.audio]: 'Asset::Audio',
};

export const FILE_TYPES = {
  [ASSET_TYPES.image]: IMAGE_FILE_TYPES,
  [ASSET_TYPES.audio]: AUDIO_FILE_TYPES
}

export const AJAX_BODY_FILE_KEYS = {
  file: {
    [ASSET_TYPES.image]: 'asset_screenshot[image]',
    [ASSET_TYPES.audio]: 'asset_audio[audio_file]'
  },
  name: {
    [ASSET_TYPES.image]: 'asset_screenshot[name]',
    [ASSET_TYPES.audio]: 'asset_audio[name]'
  },
  parent_id: {
    [ASSET_TYPES.image]: 'asset_screenshot[parent_id]',
    [ASSET_TYPES.audio]: 'asset_audio[parent_id]'
  }


}

export const TREE_DEMO_ITEM_TYPES = {
  folder: 'Demo::Folder',
  demo: 'Demo::Item'
};

export const CAN_INCLUDE_CHILDREN_TYPES = ['Asset::Folder', 'Demo::Folder']
export const BROWSER_CTRL_HOTKEYS = ['a', 'd', 'g', 's', 'S', 'F5', 'n', '+', '=', '-'];
export const INPUT_CTRL_HOTKEYS = ['a', 'c', 'v', 'x'];

export const TOOLBAR_TAB_NAMES = {
  home: 'home',
  tools: 'tools'
}

export const MIN_TOOL_WIDTH = 10;
export const MIN_TOOL_HEIGHT = 10;