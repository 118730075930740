import {
  MAX_IMAGE_SIZE
} from 'Constants';

/**
 * return maximal width and height of image and save its ratio
 * @param {Number} width original size of image
 * @param {Number} height original height of image
 * @returns {Object} returns width and height of image
 */
export function getImageInitialSizes(width, height) {


  // check, is image album oriented with ratio 16:9
  if (width / 16 * 9 < height) {
    // if we get not album img 16:9
    const newHeight = Math.min(height, MAX_IMAGE_SIZE.height);
    return {
      width: width * newHeight / height,
      height: newHeight,
    };
  }

  const newWidth = Math.min(width, MAX_IMAGE_SIZE.width);
  return {
    width: newWidth,
    height: height * newWidth / width,
  };
}
