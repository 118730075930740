import store from 'Helpers/commonDialogs';
import TrialDialog from './TrialDialog';
import TutorialDialog from './TutorialDialog';

export const CommonDialogs = ({ data }) => {
  const trialDialog = React.useRef(null);
  const tutorialDialog = React.useRef(null);

  function showTrialDialog(message) {
    trialDialog.current.open(message)
  }

  function showTutorialDialog(src) {
    tutorialDialog.current.open(src)
  }

  store.register('showTrialDialog', showTrialDialog);
  store.register('showTutorialDialog', showTutorialDialog);

  return (
    <>
      <TrialDialog ref={trialDialog} api_url={data.feedback_api_url} />
      <TutorialDialog ref={tutorialDialog} />
    </>
  )
}
