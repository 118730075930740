import PauseIcon from "@material-ui/icons/PauseCircleFilled";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import { PLAYER_STATUSES } from 'Constants/audio';

export const playAudio = (player) => {
  if (player) {
    if (player.paused) {
      player.play();
      return { playStatus: PLAYER_STATUSES.PLAY }
    }

    player.pause();
    return { playStatus: PLAYER_STATUSES.PAUSE }
  }
};


export const muteAudio = (player) => {
  if (player) {
    if (player.muted) {
      player.muted = false;
      return { muteStatus: PLAYER_STATUSES.UNMUTE };
    }

    player.muted = true;
    return { muteStatus: PLAYER_STATUSES.MUTE };
  }

  return null;
};

export const getPlayerStateFromAction = (player, action) => {
  switch (action) {
    case PLAYER_STATUSES.MUTE:
      return muteAudio(player);

    case PLAYER_STATUSES.PLAY:
      return playAudio(player)
    default:
      return playAudio(player);
  }
};

export const appendZero = (number) => (number < 10 ? `0${number}` : number);

export const getFormattedTime = (time) => {
  const dateTime = new Date(0, 0, 0, 0, 0, time, 0);
  const dateTimeM = appendZero(dateTime.getMinutes());
  const dateTimeS = appendZero(dateTime.getSeconds());

  return `${dateTimeM}:${dateTimeS}`;
};

export const getIconByPlayerStatus = (playerStatus) => {
  switch (playerStatus) {
    case PLAYER_STATUSES.PAUSE:
      return PlayIcon;
    case PLAYER_STATUSES.PLAY:
    default:
      return PauseIcon;
  }
};

export const getIconByPlayerMute = (playerStatus) => {
  switch (playerStatus) {
    case PLAYER_STATUSES.MUTE:
      return VolumeOffIcon;
    case PLAYER_STATUSES.UNMUTE:
    default:
      return VolumeUpIcon;
  }
};

export const getProgress = (currentTime, duration) => {
  return parseFloat(100 * (currentTime / duration));
}

export const getCurrentTime = (progress, duration) => {
  return parseFloat((progress * duration) / 100);
}