import { Container } from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';

export const AlertNotification = ({ title, type, children }) => {
  return (
    <Container >
      <Alert severity={type}>
        {title && (
          <AlertTitle>{title}</AlertTitle>
        )}
        {children}
      </Alert>
    </Container>
  );
};

AlertNotification.defaultProps = {
  type: 'success',
};
