import { Button, Box, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateRange, DefinedRange } from 'react-date-range';
import { dateToDisplayFormat } from 'Helpers';

const useStyles = makeStyles(() => ({
  datePickerWrapper: {
    position: 'relative'
  },
  dropdown: {
    position: 'absolute',
    top: '100%',
    left: '0',
  },
  datePicker: {
    display: 'flex'
  },
  applyButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
}));

export const DatePicker = ({ startDate, endDate, setDateRange }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const showDatePicker = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [selectedDateRange, setSelectedDateRange] = React.useState({
    startDate,
    endDate
  });

  const closeDatePicker = () => {
    setAnchorEl(null);

    if (startDate !== selectedDateRange.startDate || endDate !== selectedDateRange.endDate) {
      setDateRange(selectedDateRange);
    }
  };

  const selectDateRange = ({ selection }) => {
    const { startDate, endDate } = selection;
    setSelectedDateRange({ startDate, endDate });
  }

  const classes = useStyles();

  const dateRangeToDisplay = React.useMemo(() => {
    return {
      start: dateToDisplayFormat(selectedDateRange.startDate),
      end: dateToDisplayFormat(selectedDateRange.endDate)
    }
  }, [selectedDateRange])

  return (
    <Box className={classes.datePickerWrapper}>
      <Button variant="contained" color="primary" aria-controls="date-picker"
        aria-haspopup="true" onClick={showDatePicker}>
        {dateRangeToDisplay.start} - {dateRangeToDisplay.end}
      </Button>
      <Menu className={classes.dropdown} id="date-picker"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeDatePicker}>
        <Box className={classes.datePicker}>
          <DefinedRange
            onChange={selectDateRange}
            ranges={[{ ...selectedDateRange, key: 'selection' }]}
          />
          <DateRange
            ranges={[{ ...selectedDateRange, key: 'selection' }]}
            onChange={selectDateRange}
            rangeColors={["#60CAE6"]}
            months={2}
            direction="horizontal"
            renderStaticRangeLabel={<button>123123123</button>}
          />
        </Box>
        <Box className={classes.applyButtonWrapper} pr={1}>
          <Button variant="contained" color="primary" onClick={closeDatePicker}>Apply</Button>
        </Box>
      </Menu>
    </Box>
  )
}
