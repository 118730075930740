import { Paper, Grid, Slider, Typography, makeStyles, Box } from '@material-ui/core';
import { getFormattedTime, getIconByPlayerStatus } from 'Helpers';
import { PLAYER_STATUSES } from 'Constants/audio';
import { useAudio } from 'Hooks';

const audioPlayerStyles = makeStyles((theme) => ({
  paper: {
    padding: '5px',
    marginTop: '12px'
  },
  playIcon: {
    fontSize: '30px',
    cursor: 'pointer',
  },
  sliderRail: {
    backgroundColor: theme.palette.grey[600]
  },
  slider: {
    display: 'flex',
    alignItems: 'center',
  },
  sliderTimeStart: {
    flexShrink: 0,
    paddingRight: '12px'
  },
  sliderTimeEnd: {
    flexShrink: 0,
    paddingLeft: '12px'
  },
  sliderControl: {
    width: '100%',
  },
  audioTime: {
    fontSize: '14px'
  }
}));

export function AudioPlayer({ item }) {
  const classes = audioPlayerStyles();
  const player = React.useRef(null);
  const { audioState, onProgressChange, triggerAction } = useAudio({ player, item });

  function handleChange(_, progress) {
    onProgressChange(progress)
  };

  const PlayStatusIcon = React.useMemo(() => {
    return getIconByPlayerStatus(audioState.playStatus);
  }, [audioState.playStatus, item]);

  return (
    <>
      <audio src={item.src} ref={player} controls={true} hidden={true} preload="true"></audio>
      <Paper className={classes.paper} elevation={1}>
        <Grid className={classes.playerControlsWrapper} alignItems="center" justify="center" spacing={1} container>
          <Grid xs={2} item>
            <PlayStatusIcon className={classes.playIcon} color="primary" onClick={() => triggerAction(PLAYER_STATUSES.PLAY)}
              focusable="true"
            />
          </Grid>
          <Grid xs={10} item>
            <Box className={classes.slider}>
              <Box className={classes.sliderTimeStart} >
                <Typography className={classes.audioTime} align="center" gutterBottom noWrap>
                  {getFormattedTime(audioState.current)}
                </Typography>
              </Box>
              <Box className={classes.sliderControl}>
                <Slider onChange={handleChange} value={audioState.progress}
                  variant="determinate" color="primary"
                  classes={{
                    rail: classes.sliderRail,
                  }}
                />
              </Box>
              <Box className={classes.sliderTimeEnd}>
                <Typography className={classes.audioTime} align="center" gutterBottom noWrap>
                  {getFormattedTime(audioState.duration)}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
